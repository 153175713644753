<template>
  <div class="detail" :class="styles[isColor]">
    <van-nav-bar
      class="detail-header"
      :title="navTitle"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="header-bg">
      <img :src="headerImg" />
      <div class="header-mask"></div>
    </div>
    <div class="detail-image-list" v-if="isColor === 4">
      <div
        class="detail-image"
        v-for="(item, index) in imgList"
        :key="index"
        @click="prevImg(item, index)"
      >
        <div v-html="item"></div>
      </div>
    </div>
    <div class="detail-body" :class="isHeader ? '' : 'no-header'" v-else>
      <div class="detail-title" v-if="isHeader">{{ title }}</div>
      <div
        class="detail-section"
        v-if="[0, 1].includes(isColor)"
        v-html="section"
      ></div>
      <div
        class="detail-section"
        v-if="[2, 3].includes(isColor)"
        v-html="section"
      ></div>
    </div>
    <div class="preview-box" v-if="selectItem">
      <van-icon class="preview-close-icon" name="clear" @click="closePreview" />
      <div class="preview-item" v-html="selectItem"></div>
    </div>
  </div>
</template>

<script>
import { newsDetail } from "@/service";
export default {
  name: "detail",
  data() {
    return {
      isHeader: true,
      styles: ["orange", "cyan", "purple", "blur", "grey"],
      isColor: 0, // 0学习指南，1欢迎词，2珞珈风华，3教学点介绍, 4学习掠影
      navTitle: "学习篇",
      headerImg: require("../static/whdx2.png"),
      title: "学习安排",
      section: `暂无数据`,
      imgList: [],
      selectItem: "",
    };
  },
  created() {
    let { type } = this.$route.query;
    this.isColor = parseInt(type ? type : 0);
    let is = [0, 1].includes(this.isColor);
    this.isHeader = is;

    if (this.isColor === 2) {
      this.navTitle = "珞珈风华";
    } else if (this.isColor === 3) {
      this.navTitle = "教学点介绍";
      let obj = JSON.parse(localStorage.getItem("pointDetail"));
      if (obj) {
        this.section = obj.content;
        this.title = obj.title;
      }
    } else if (this.isColor === 4) {
      this.navTitle = "学习掠影";
    } else {
      this.navTitle = "学习篇";
    }
    if (this.$route.query.id) this.getDetail();
  },
  methods: {
    async getDetail() {
      let { data } = await newsDetail(
        localStorage.getItem("SID"),
        this.$route.query.id
      );
      this.section = data.articleData.content;
      this.title = data.title;
      this.navTitle = this.title
      if (this.isColor === 4) {
        this.imgList = this.regImage(data.articleData.content);
      }
    },
    regImage(data) {
      const reg = /<img.*?src=["|']?(.*?)["|']?\s.*?>|<video.*?>.+?<\/video>/g;
      return data.match(reg);
    },
    prevImg(item) {
      this.selectItem = item;
    },
    closePreview() {
      this.selectItem = null;
    },
    onClickLeft() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="less">
.detail {
  font-family: PingFangSC-Semibold, PingFang SC;
  min-height: 100vh;
  padding-bottom: 20px;
  box-sizing: border-box;
  &.orange {
    background: rgba(255, 191, 50, 1);
    .header-mask {
      background: linear-gradient(
        rgba(255, 191, 50, 0.1),
        rgba(255, 191, 50, 1)
      );
    }
    .detail-title {
      background: rgba(255, 191, 50, 1);
      border: 4px solid rgba(199, 147, 0, 1);
    }
  }
  &.cyan {
    background: rgba(65, 170, 255, 1);
    .header-mask {
      background: linear-gradient(
        rgba(65, 170, 255, 0.1),
        rgba(65, 170, 255, 1)
      );
    }
    .detail-title {
      background: rgba(65, 170, 255, 1);
      border: 4px solid rgba(0, 110, 199, 1);
    }
  }
  &.purple {
    background: rgba(127, 129, 250, 1);
    .header-mask {
      background: linear-gradient(
        rgba(127, 129, 250, 0.1),
        rgba(127, 129, 250, 1)
      );
    }
  }
  &.blur {
    background: rgba(87, 148, 245, 1);
    .header-mask {
      background: linear-gradient(
        rgba(87, 148, 245, 0.1),
        rgba(87, 148, 245, 1)
      );
    }
  }
  &.grey {
    background: rgba(225, 225, 225, 0.3);
  }
  .header-bg {
    width: 100vw;
    height: 166px;
    position: relative;
    img {
      width: 100%;
      height: 100%;
    }
    .header-mask {
      width: 100%;
      height: 167px;
      position: absolute;
      top: 0;
    }
  }
  .detail-body {
    background: #ffffff;
    border-radius: 12px;
    margin: -30px 15px 20px;
    padding: 36px 18px 26px;
    position: relative;
    &.no-header {
      margin: -68px 15px 0px;
      padding-top: 0;
    }
    .detail-title {
      width: 170px;
      height: 50px;
      line-height: 50px;
      border-radius: 30px;
      margin: -61px auto 0;
      text-align: center;
      color: #fff;
      font-weight: 600;
      font-size: 29px;
    }
    .detail-section {
      font-size: 19px;
      color: #3f3f3f;
      line-height: 32px;
      padding-top: 14px;
      overflow-y: scroll;
      .section-title {
        font-weight: bold;
        margin-bottom: 12px;
      }
      .section-body {
        line-height: 32px;
        margin-bottom: 36px;
      }
    }
  }
  .detail-image-list {
    display: flex;
    flex-wrap: wrap;
    background: #ffffff;
    border-radius: 12px;
    margin: -30px 15px 20px;
    padding: 20px 18px 26px;
    position: relative;
    .detail-image {
      width: 100%;
      position: relative;
      margin-bottom: 10px;
      img {
        width: 100%;
      }
      video {
        width: 100%;
        height: 100%;
      }
      .video {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
      }
    }
  }
  .preview-box {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.9);
    .preview-close-icon {
      position: absolute;
      right: 16px;
      top: 16px;
      z-index: 1;
      color: #c8c9cc;
      font-size: 22px;
      cursor: pointer;
    }
    .preview-item {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      img,
      video {
        width: 100%;
        transition-property: transform;
      }
    }
  }
}
</style>
